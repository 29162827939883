import React, { useState, useCallback, useEffect } from 'react'
import { graphql, PageProps } from 'gatsby'
import { motion, Variants } from 'framer-motion'
import get from 'lodash/get'

import Layout from '../../components/layout'
import { ArticlePreviewWithRef } from '../../components/articlePreview'
import { useEventListener, useWindow } from '../../hooks'

const PosedArticlePreview = motion(ArticlePreviewWithRef)

const times = ['VŠE', 'UPLYNULÉ', 'BUDOUCÍ']

interface ActualityNode {
  excerpt: string
  fields: {
    slug: string
  }
  frontmatter: {
    tmstp: string
    date: string
    eventDate: string
    eventTmstp: string
    eventDateEnd: string
    title: string
    tags: string[]
  }
}

interface ActualityPageProps extends PageProps {
  data: {
    allMarkdownRemark: {
      edges: {
        node: ActualityNode
      }
    }
    dataJson: {
      tags: string[]
    }
  }
}

const Aktuality = (props: ActualityPageProps) => {
  const postsAll: Array<{ node: ActualityNode }> = get(
    props,
    'data.allMarkdownRemark.edges'
  )
  const tags: string[] = get(props, 'data.dataJson.tags')
  const today = Math.floor(Date.now() / 1000)

  const window = useWindow()

  const clearHash = useCallback(() => {
    if (window && window.location.hash) window.location.hash = ''
  }, [window])

  const [timeFilter, setTimeFilter] = useState(0)
  const createTimeHandler = useCallback(
    (i) => () => setTimeFilter(i),
    [setTimeFilter]
  )
  const filterTime = useCallback(
    ({ node }) => {
      if (timeFilter === 0) return node
      if (timeFilter === 1 && node.frontmatter.eventTmstp < today) return node
      if (
        timeFilter === 1 &&
        !node.frontmatter.eventTmstp &&
        node.frontmatter.tmstp < today
      )
        return node
      if (timeFilter === 2 && node.frontmatter.eventTmstp >= today) return node
      return null
    },
    [timeFilter]
  )

  const [tagFilter, setTagFilter] = useState<number[] | false>([])
  const createTagsHandler = useCallback(
    (i) => () => {
      clearHash()
      setTagFilter((filter: number[] | false) => {
        if (filter === false) {
          return [i]
        }
        if (filter.includes(i)) {
          filter.splice(filter.indexOf(i), 1)
          return [...filter]
        } else {
          filter.push(i)
          return [...filter]
        }
      })
    },
    [setTagFilter]
  )

  const handleOthers = useCallback(() => {
    clearHash()
    setTagFilter((filter) => (filter !== false ? false : []))
  }, [clearHash, setTagFilter])

  const filterTags = useCallback(
    ({ node }) => {
      const articleTags = node.frontmatter.tags
      if (tagFilter === false) {
        return !articleTags ? node : null
      }
      if (tagFilter.length) {
        if (!articleTags) return null
        return !~tagFilter.findIndex((i) => articleTags.includes(tags[i]))
          ? null
          : node
      } else {
        return node
      }
    },
    [tagFilter]
  )

  const onHashChange = useCallback(() => {
    const { hash } = props.location
    let newTag: number
    if (hash) {
      newTag = tags.findIndex((tag) => decodeURI(hash) === `#${tag}`)
      if (!~newTag) return
      setTagFilter([newTag])
    }
  }, [tags, setTagFilter])

  useEffect(onHashChange, [props.location.hash])

  useEventListener('hashchange', onHashChange)

  const posts = postsAll.filter(filterTime).filter(filterTags)

  return (
    <Layout location={props.location} customClass="news">
      <header>
        <h1>Aktuality z odboru všestrannosti</h1>
      </header>
      <nav className="article-filtres">
        <span>
          {times.map((filterName, i) => (
            <button
              key={filterName}
              className={timeFilter === i ? 'active' : undefined}
              onClick={createTimeHandler(i)}
            >
              {filterName}
            </button>
          ))}
        </span>
        <span className="divider" />
        <span>
          {tags.map((tagName, i) => (
            <button
              key={tagName}
              className={
                tagFilter && tagFilter.includes(i) ? 'active' : undefined
              }
              onClick={createTagsHandler(i)}
            >
              {tagName}
            </button>
          ))}
          <button
            className={tagFilter === false ? 'active' : undefined}
            onClick={handleOthers}
          >
            různé
          </button>
        </span>
      </nav>
      <div className="articles">
        {posts.map(({ node }, i) => (
          <PosedArticlePreview
            key={node.fields.slug}
            slug={node.fields.slug}
            date={node.frontmatter.date}
            eventDate={node.frontmatter.eventDate}
            eventDateEnd={node.frontmatter.eventDateEnd}
            title={get(node, 'frontmatter.title') || node.fields.slug}
            excerpt={node.excerpt}
            tags={node.frontmatter.tags}
            location={props.location}
            custom={i}
            variants={articlePreviewVariants}
            initial="init"
            animate="appear"
          />
        ))}
      </div>
    </Layout>
  )
}

export default Aktuality

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { regex: "/aktuality/" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            tmstp: date(formatString: "X")
            date: date(formatString: "D. M. YYYY")
            eventDate: eventDate(formatString: "D. M. YYYY")
            eventTmstp: eventDate(formatString: "X")
            eventDateEnd(formatString: "D. M. YYYY")
            title
            tags
          }
        }
      }
    }
    dataJson {
      tags
    }
  }
`

const articlePreviewVariants: Variants = {
  init: {
    opacity: 0,
    y: -10,
  },
  appear: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i === undefined ? 0 : i * 0.05,
    },
  }),
}
