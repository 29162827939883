import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { IconDate } from './icons'
import { validateDate, getEventDate } from '../utils'


class ArticlePreview extends React.Component {
  static propTypes = {
    slug: PropTypes.string.isRequired,
    date: PropTypes.string,
    eventDate: PropTypes.string,
    eventDateEnd: PropTypes.string,
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string,
    tags: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(null)]),
    location: PropTypes.object,
    forwardedRef: PropTypes.func,
  }

  render() {
    const {
      slug, date, eventDate, eventDateEnd,
      title, excerpt, tags, location, forwardedRef
    } = this.props

    return (
      <article key={slug} className="article-preview" ref={forwardedRef}>
        <h3>
          <Link to={slug}>
            {title}
          </Link>
        </h3>
        <small className="article-date" title="Datum publikace">{date}</small>
        <p dangerouslySetInnerHTML={{ __html: excerpt }} />
        <div className="article-tags">
          {tags && tags.map(tag =>
            <Link to={`${location.pathname}#${tag}`} className="article-tag" key={tag}>{tag}</Link>
          )}
        </div>
        <footer>
          {validateDate(eventDate) &&
            <small title="Datum konání akce">
              <IconDate />
              <b>{getEventDate(eventDate, eventDateEnd)}</b>
            </small>
          }
          <Link to={slug} className="read-more">číst více</Link>
        </footer>
      </article>
    )
  }
}

export default ArticlePreview

export const ArticlePreviewWithRef = React.forwardRef(
  (props, ref) => <ArticlePreview forwardedRef={ref} {...props} />
)
